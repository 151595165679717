<template>
  <div class="boss">
    <div class="logoImg">
      <!-- <img src="../assets/img/logo.png" alt="" /> -->
    </div>
    <div class="livebg">
      <div class="shaImg">
        <p class="shen">剩余观看时间：{{ str }}s</p>
        <div class="videos">
          <!-- <video
            ref="myVideo"
            id="myplayer"
            muted
            :src="videoHls"
            v-show="!isShow && !show && !isplay"
            :controls="false"
            playsInline
            webkit-playsinline
            @canplay="getVidDur()"
          ></video> -->
          <video-player
            ref="myVideo"
            :playsinline="true"
            v-show="!isShow && !show && !isplay"
            :options="playerOptions"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
          >
          </video-player>
        </div>
        <div class="btn">
          <img src="../assets/img/btn.png" alt="" @click="clickBtn" />
        </div>
      </div>
    </div>
    <van-dialog
      v-model="isShow"
      confirm-button-text="去注册"
      @confirm="goDownload"
    >
      <div>
        <p class="title">温馨提示</p>
        <p class="more margin">观看时间已到</p>
        <p class="more">更多精彩内容尽在禾乡农场APP！</p>
      </div>
    </van-dialog>
    <van-popup v-model="show">
      <div class="bossBox">
        <p class="verify">手机号绑定验证</p>
        <div>
          <van-cell-group>
            <van-field
              v-model="phone"
              clearable
              :border="false"
              type="number"
              maxlength="11"
              placeholder="请填写手机号"
              left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
            />
          </van-cell-group>
        </div>
        <div class="yanzheng">
          <van-cell-group class="cell">
            <van-field
              v-model="code"
              :border="false"
              placeholder="验证码"
              left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
            />
          </van-cell-group>
          <div v-show="codeShow" class="song" @click="getCode">获取验证码</div>
          <div v-show="!codeShow" class="song">
            {{ yzmTitle }}
          </div>
        </div>
        <div class="sure" @click="isTrue">下载app观看</div>
      </div>
    </van-popup>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <div v-if="isweixin" class="isWeixinOpen">
      <img src="../assets/img/zao.png" alt="" />
    </div>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>

<script>
import { getLiveUrl, sendSms, bindFriend } from '../api/index'
// import EZUIPlayer from "ezuikit/ezuikit";
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
export default {
  components: {
    't-captcha-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    },
    videoPlayer
  },
  data() {
    return {
      titleShow: false, //提示
      msg: '', //提示
      show: false, // 手机号绑定
      isShow: false,
      videoHls: '',
      second: 40, //秒
      time: null,
      uuidA: '',
      id:'',
      isplay: false,
      uuid: '', //唯一标识
      str: '00',
      phone: '',
      code: '',
      yzmTitle: '',
      codeShow: true, //图片验证
      register: false, // 是否注册
      isweixin: false,
      message: '',
      dialogShow: false, //提示
      playerOptions: {
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'application/x-mpegURL',
            src: '' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      }
    }
  },
  watch: {
    second(val, old) {
      if (old == 1) {
        this.openpop()
        this.stop()
      }
    }
  },
  created() {
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) return (this.isweixin = true)
    this.GetLiveUrl()
  },
  mounted() {
    this.id = this.$route.query.ls_id
    console.log(this.id)
    // if (this.$route.query.invite_code) {
    //   this.doCopy(this.$route.query.invite_code)
    // }
    // // 监听视频暂停
    // this.$refs.myVideo.addEventListener("pause", () => {
    //   this.stop();
    //   this.$refs.myVideo.addEventListener("play", () => {
    //     this.getVidDur();
    //   });
    // });
  },
  methods: {
    // 剪贴板
    dataProcessing(val) {
      // this.message = '#' + val + '#'
      this.message = val
    },
    // 剪贴板
    doCopy: function (val) {
      this.dataProcessing(val)
      this.$copyText(this.message).then(
        function (e) {
          // alert('Copied')
          console.log(e, 9999)
          console.log(e.text, 9999)
        },
        function (e) {
          // alert('Can not copy')
          console.log(e, 8888)
          console.log(e.text, 888888)
        }
      )
    },
    // 开始播放
    onPlayerPlay(e) {
      console.log(e)
      this.getVidDur()
    },
    // 暂停
    onPlayerPause(e) {
      console.log(e)
      this.stop()
    },
    // 获取直播间地址
    async GetLiveUrl() {
      if (window.localStorage.uuid == undefined) {
        window.localStorage.setItem('uuid', this.uuids())
      }
      let data = {
        ls_id: this.$route.query.ls_id || '137',
        uuid: window.localStorage.getItem('uuid')
      }
      let res = await getLiveUrl(data)
      if (res.data.code != 200) return
      this.second = res.data.data.info.livetime
      this.playerOptions.sources[0].src = res.data.data.info.living_url
      console.log(this.playerOptions.sources[0].src)
      // window.localStorage.setItem("uuid", 111);
    },
    uuids() {
      var s = []
      var hexDigits = '0123456789abcdef'
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4'
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
      s[8] = s[13] = s[18] = s[23] = '-'
      this.uuidA = s.join('')
      return this.uuidA
    },
    goDownload() {
      console.log(1)
      this.show = true
      this.isplay = true
      this.isShow = false
    },
    getVidDur() {
      window.clearInterval(this.time)
      this.time = setInterval(this.timer, 1000)
    },
    timer() {
      //定义计时函数
      if (this.second > 0) {
        this.second = this.second - 1 //秒
      } else {
        clearInterval(this.timers)
      }
      this.str = this.toDub(this.second)
    },
    //补零操作
    toDub(n) {
      if (n < 10) {
        return '0' + n
      } else {
        return '' + n
      }
    },
    //暂停
    stop() {
      clearInterval(this.time)
    },
    openpop() {
      this.isShow = true
    },
    async clickBtn() {
      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      if (this.register) {
        // this.$router.push('/download')
        this.$router.push({
            path: '/Skippage',
            query: { scene:'Home',pageName:"ls_info",idkey:"id",idvalue:this.id }
            })
      } else {
        // this.show = true
        if (this.$route.query.rid != '' && this.$route.query.rid != undefined) {
          console.log(123)
          console.log(this.$route.query.rid, this.$route.query.store_id)

          this.show = true
        } else if (
          this.$route.query.store_id != '' &&
          this.$route.query.store_id != undefined &&
          this.$route.query.store_id != 1
        ) {
          this.show = true
        } else if (this.$route.query.invite_code) {
          console.log(456)
          const u = navigator.userAgent
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          if (isiOS) {
            window.location.href =
              'https://itunes.apple.com/cn/app/id1614216413'
          } else {
            // this.$router.push('/download')
            this.$router.push({
            path: '/Skippage',
            query: { scene:'Home',pageName:"ls_info",idkey:"id",idvalue:this.id }
            })
          }
        } else {
          // this.show = true
          const u = navigator.userAgent
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          if (isiOS) {
            window.location.href =
              'https://itunes.apple.com/cn/app/id1614216413'
          } else {
            // this.$router.push('/download')
            this.$router.push({
            path: '/Skippage',
            query: { scene:'Home',pageName:"ls_info",idkey:"id",idvalue:this.id }
            })
          }
        }
      }
    },
    async isTrue() {
      let data = {
        rid: this.$route.query.rid || '',
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code
      }
      let res = await bindFriend(data)
      console.log(res, 'resres')
      if (res.data.code != 200) {
        this.msg = res.data.message
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.show = !false
      this.register = true
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
      } else {
        // this.$router.push('/download')
        this.$router.push({
          path: '/download',
          query: { invite_code: this.$route.query.invite_code }
        })
      }
    },
    getCode() {
      if (!this.phone) {
        this.msg = '请输入手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!reg.test(this.phone)) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.showCodeImg()
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        console.log(coderes)
        const { randstr, ticket } = coderes
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res)
            if (res.data.code == 200) {
              let time = 60
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer)
                  this.codeShow = true
                } else {
                  this.codeShow = false
                  this.yzmTitle = time + '秒后重试'
                  time--
                }
              }, 1000)
              this.msg = '验证码获取成功'
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            } else {
              this.msg = res.data.message
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            }
          }
        )
      })
      captcha1.show()
    }
  },

  updated() {
    // console.log(this.videoHls, "this.videoHls");
    // if (this.videoHls != "") {
    //   var player = new EZUIPlayer("myplayer");
    //   console.log(player, "player");
    //   player.play();
    // }
  }
}
</script>

<style lang="scss" scoped>
.boss {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/img/live.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
}
.logoImg {
  width: 3.2rem;
  height: 2.7rem;
  display: flex;
  justify-content: flex-end;
  // img {
  //   width: 3.2rem;
  //   height: 2.7rem;
  //   object-fit: contain;
  //   margin-right: 0.5rem;
  // }
}
.livebg {
  width: 100%;
  display: flex;
  justify-content: center;
}
.shaImg {
  width: 10rem;
  height: 11.9rem;
  background-image: url('../assets/img/sha.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .shen {
    position: absolute;
    bottom: 1.4rem;
    left: 3.45rem;
    font-size: 0.4rem;
    color: #a64234;
  }
  .videos {
    width: 7.4rem;
    height: 4.5rem;
    margin-top: 4rem;
    background: #000;
    /deep/ #myplayer {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    position: absolute;
    bottom: -2rem;
    left: 2.25rem;
    img {
      width: 6rem;
      height: auto;
    }
  }
}
.van-dialog {
  width: 7rem;
  height: 4.3rem;
  z-index: 999999 !important;
  /deep/ .van-dialog__content {
    height: 2.8rem;
    padding: 0.2rem;
    .title {
      width: 100%;
      text-align: center;
      font-size: 0.5rem;
      font-weight: 400;
      color: #999999;
      margin-top: 0.3rem;
    }
    .more {
      width: 100%;
      text-align: center;
      font-size: 0.4rem;
      font-weight: 400;
      color: #333333;
    }
    .margin {
      margin-top: 0.43rem;
    }
  }

  /deep/ .van-button__content,
  /deep/ .van-hairline--top,
  /deep/ .van-button,
  /deep/ .van-button__text {
    height: 1.1rem;
  }
  /deep/ .van-button__text {
    line-height: 1.1rem;
    font-size: 0.4rem;
    color: #43d227;
  }
}

.van-popup {
  border-radius: 0.15rem;
  overflow: hidden;
}
.bossBox {
  width: 8rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  .verify {
    font-size: 0.53rem;
    color: #333;
    font-weight: 400;
    padding: 0.8rem 0 0.25rem 0;
  }
  .yanzheng {
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .song {
      width: 2.76rem;
      height: 1.15rem;
      background: #43d227;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      font-size: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fefefe;
      font-weight: 400;
    }
  }
  .sure {
    width: 4.6rem;
    height: 1.1rem;
    background: #43d227;
    font-size: 0.45rem;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 0.4rem;
    border-radius: 2rem;
  }
}
.van-cell-group {
  margin-top: 0.2rem;
  width: 7rem;
  .van-cell {
    height: 1.2rem;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  /deep/ .van-icon {
    font-size: 0.8rem;
    margin: 0 0.3rem 0 0.34rem;
  }

  /deep/ .van-field__body {
    font-size: 0.41rem;
  }
}
.cell {
  width: 4rem;
  /deep/ .van-icon {
    font-size: 0.65rem;
    margin: 0 0.3rem 0 0.41rem;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
.isWeixinOpen {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background: rgba(#000, 0.4);
  top: 0;
  display: flex;
  justify-content: flex-end;
  img {
    width: 7rem;
    height: 7rem;
    margin-right: 0.2rem;
  }
}
</style>
